@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

* {
  font-family: 'Roboto', sans-serif;
}
.App {
  text-align: center;
}

.login-wrapper {
  height: 100vh;
  position: relative
}

.login-container {
    margin: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    width: 100%;
}

.layout .logo {
  width: 220px;
  height: 64px;
  background-image: url('./assets/images/primagas_logo_wb.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  float: left;
}

header .ant-menu li,
header .ant-menu li a,
header .ant-menu li .ant-menu-submenu-title {
  color: #000000 !important;
}

header .ant-menu i {
  font-size: 18px !important;
  color: #000000;
}

header .ant-menu li:hover,
header .ant-menu li:hover .ant-menu-submenu-title {
  color: #000000 !important;
  background-color: #FFFFFF !important;
}

.ant-menu-dark .ant-menu-sub {
  background-color: #000000 !important;
}

.filters {
  display: block;
}
.filters-item {
  display: block;
  padding: 10px 5px;
}
.button-item {
  float: right;
  padding: 10px 5px;
}
.filters-item .title {
  display: block;
  margin-bottom: 5px;
}
.filters-item button:nth-child(2) {
  margin-left: 5px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.customer-data {
  display: flow-root;
  list-style: none;
  padding: 0;
  margin: 0;
}

.customer-data li {
  border-bottom: #eeeeee solid 1px;
  padding: 10px 0;
  float: left;
  width: 33.3%;
}

.customer-data li .title {
  font-weight: 800;
  display: block;
  width: 100%;
}

.customer-data li .value {
  display: block;
  width: 100%;
}

.ant-pagination {
  margin-right: 10px !important;
}

.events {
  list-style: none;
  margin: 0;
  padding: 0;
}

.events li {
  margin-bottom: 5px;
}

.events li .ant-tag {
  width: 95% !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-form-item-label {
  width: 6rem;
}